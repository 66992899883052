import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import tw from 'twin.macro'
import { useHeaderContext } from '../context/Header'

import { flatListToHierarchical } from '../helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const NavItem = ({item, firstChild}) => {
    const [open, setOpen] = useState(false)
    const toggleOpen = () => setOpen(!open)

    const hasChildren = item.children.length
    const { isCommercial } = useHeaderContext();

    const linkStyles = [
        'white-space: nowrap;',
        tw`leading-4 block w-full text-left py-6 transition ease-in-out duration-100 border-t
            hover:(cursor-pointer)
            lg:(border-0 px-3 py-2)
            xl:(px-4)`,
        isCommercial 
            ? tw`text-blue-600
                hover:(text-blue-500)
                focus:(text-blue-500)`
            : tw`text-gray-100
                hover:(text-yellow-600)
                focus:(text-yellow-600)`,
        firstChild && tw`border-0`
    ]

    return (
        <li tw="relative">
            {hasChildren ?
                <>
                    <button
                        css={[
                            ...linkStyles,
                            (open && isCommercial) && tw`text-blue-600`,
                            (open && !isCommercial) && tw`text-yellow-600`
                        ]}
                        onClick={toggleOpen}
                    >
                        {item.description ? item.description : null}
                        {item.label}
                        <FontAwesomeIcon icon={['fas', 'chevron-down']} size="sm" css={[tw`ml-2 transform rotate-0 lg:(rotate-0)`, open && tw`rotate-180`]} />
                    </button>
                    <DropdownMenu items={item.children} open={open} toggleOpen={toggleOpen} />
                </>
            :
                <Link
                    css={linkStyles}
                    to={item.connectedNode?.node.uri || item.path}
                >
                    {item.description ? <FontAwesomeIcon icon={['far', item.description]} tw="mr-2" /> : null}
                    {item.label}
                </Link>
            }
        </li>
    )
}

const DropdownMenu = ({ items, open, toggleOpen }) => {
    return (
        <div
            css={[
                `left: 50%;`,
                tw`transition ease-in-out duration-150 transform lg:(-translate-x-1/2 absolute bg-white p-4 w-64 rounded-lg shadow-lg mt-4)`,
                tw`hidden lg:(block pointer-events-none opacity-0 translate-y-1)`, // Closed state
                open && tw`block lg:(opacity-100 pointer-events-auto translate-y-0)` // open state
            ]}
            aria-expanded={open}
        >
            <div
                css={[
                    tw`hidden lg:(block) absolute w-0 h-0 border-transparent border-l-8 border-r-8 border-b-8 -translate-x-1/2`,
                    `border-bottom-color: #fff; bottom: 100%; left: 50%;`
                ]}
            ></div>
            <div tw="flex flex-col-reverse">
                <ul>
                    {items.map((child, i) => (
                        <Link
                            tabIndex={open ? 0 : -1}
                            css={[
                                tw`block p-4 text-gray-100 lg:(text-gray-800 py-2 px-5 rounded-md transition ease-in-out duration-100)
                                hover:(bg-yellow-600)
                                focus:(bg-yellow-600)
                                active:(bg-yellow-500)`,
                                
                            ]}
                            to={child.connectedNode?.node.uri || child.path}
                        >
                            {child.label}
                        </Link>
                    ))}
                </ul>
                <div tw="text-right">
                    <button
                        tabIndex={open ? 0 : -1}
                        css={[
                            tw`hidden lg:(inline) p-2 leading-4 text-4xl text-yellow-600 cursor-pointer focus:(outline-none)
                            hover:(text-yellow-500)
                            focus:(text-yellow-500 shadow-outline)`
                        ]}
                        onClick={toggleOpen}
                    >×</button>
                </div>
            </div>
        </div>
    )
}

const SkipNavLink = () => {
    
    return (
        <Link
            to="#main"
            tw="absolute opacity-0 pointer-events-none z-50 px-4 py-1 text-sm bg-yellow-600 rounded
            focus:(opacity-100 pointer-events-auto)"
        >
            Skip Main Navigation
        </Link>
    )
}

const Navbar = ({ open, toggleOpen }) => {
    const queryResult = useStaticQuery(graphql`{
        headerMenu: wpMenu(slug: {eq: "header"}) {
            menuItems {
                nodes {
                    key: id
                    parentId
                    label
                    description
                    connectedNode {
                        node {
                            ... on WpContentNode {
                                uri
                            }
                        }
                    }
                    path
                }
            }
        }
        commercialHeaderMenu: wpMenu(slug: {eq: "header-commercial"}) {
            menuItems {
                nodes {
                    key: id
                    parentId
                    label
                    description
                    connectedNode {
                        node {
                            ... on WpContentNode {
                                uri
                            }
                        }
                    }
                    path
                }
            }
        }
    }`)


    const { isCommercial }= useHeaderContext();
    const menuItems = isCommercial 
        ? queryResult?.commercialHeaderMenu?.menuItems 
        : queryResult?.headerMenu?.menuItems; 

    const navItems = flatListToHierarchical(menuItems.nodes)

    return (
        <nav
            css={[
                'top:100%;',
                tw`flex items-center p-8 hidden absolute w-full
                lg:(border-l-8 border-yellow-600 relative px-2 py-0 flex top-auto w-auto)
                xl:(border-0 ml-16)`,
                (isCommercial) 
                    ? tw`bg-yellow-600 lg:(border-l-8 border-blue-600)`
                    : tw`bg-blue-600 lg:(border-l-8 border-yellow-600)`,
                open && tw`flex`,
            ]}
            aria-label="Main Site"
        >
            <SkipNavLink />
            <div
                css={[
                    `right: 100%; background: linear-gradient(-62deg, transparent 40%, ${
                        isCommercial 
                            ? "#194798" 
                            : "#ffd100" 
                        } 0 60%, #fff 60%);`,
                    tw`hidden absolute h-full w-20 xl:(block)`,
                    isCommercial 
                        ? tw`bg-yellow-600`
                        : tw`bg-blue-600`,
                ]}
                // aria-role="presentation"
            />
            <ul
                tw="flex flex-col w-full lg:(flex-row)"
                aria-label="Navigation Links"
            >
                {navItems.map((item, i) => <NavItem item={item} firstChild={i === 0} />)}
            </ul>
        </nav>
    )
}

export default Navbar