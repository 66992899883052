import React, {createContext, useContext, useReducer, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';

const HeaderContext = createContext(null);

const HeaderProvider = ({ children, headerType }) => (
    <HeaderContext.Provider value={{ 
        headerType,
        isCommercial : ( headerType === 'commercial'),
        isResidential : ( headerType === 'residential'),
    }}>
        {children}
    </HeaderContext.Provider>
);


HeaderProvider.propTypes = ({
    children : PropTypes.node.isRequired,
    headerType: PropTypes.oneOf(['residential', 'commercial']),
});

HeaderProvider.defaultProps = ({
    headerType: 'residential'
})

const useHeaderContext = () => useContext(HeaderContext);

export {
    HeaderProvider as default,
    useHeaderContext,
    HeaderContext
}