import React, { useState } from 'react'
import tw from 'twin.macro'
import Container from './Container'

import Navbar from './Navbar'
import Logos from './Logos'
import { LocationFinder } from './LocationFinder'
import { Link } from 'gatsby'
import { ALink } from './A';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import { useHeaderContext } from '../context/Header'

const Header = () => {
    const [navbarOpen, setNavbarOpen] = useState(false)

    const toggleNavbar = () => setNavbarOpen(!navbarOpen)
    const {headerType, isCommercial, isResidential }= useHeaderContext();

    return (

        <>
        {isCommercial && (
            <div 
                aria-role="announcement"
                tw="w-full bg-blue-100 sticky"
            >
                <Container tw="py-3 text-center">
                    <p tw="text-blue-800">
                        You are currently in Commercial Pest Control,
                        {' '}
                        <ALink to="/">Click Here<span tw="sr-only">Residential Pest Control</span></ALink>
                        {' '}
                        for Residential Pest Control.
                    </p>
                </Container>
            </div>
        )}
        <header
            css={[
                `background: linear-gradient(to right, white 50%, ${isCommercial ? "#ffd100" : "#194798"} 50%);`,
                tw`shadow-md relative z-50`
            ]}
            aria-label="Site Header"
        >
            <div
                tw="mx-auto bg-white flex
                flex-col w-full
                lg:(flex-row justify-between items-center items-stretch)
                xl:(max-w-screen-xl)"
            >
                <div tw="py-4 pl-2 xl:(pl-0) flex items-center justify-center relative">
                    <Link to="/" alt="Cook’s Pest Control, select to go home">
                        <span tw="sr-only">Cook's Pest Control</span>
                        <Logos.TextOnly tw="h-10 w-auto" />
                        <span css={[ tw`text-sm`, !isCommercial && tw`hidden` ]}>Commercial Services</span>
                    </Link>
                    <LocationFinder tw="border-l-2 pl-4 ml-4 xl:(pl-6 ml-6) hidden md:(flex)"/>
                    <button
                        tw="block absolute right-0 w-16 text-center py-2 text-yellow-600 lg:(hidden)"
                        onClick={toggleNavbar}
                    >
                        <span tw="sr-only">Toggle the navigation menu</span>
                        <FontAwesomeIcon icon={navbarOpen ? faTimes : faBars} size="2x" />
                    </button>
                </div>
                <Navbar open={navbarOpen} toggleOpen={toggleNavbar} />
            </div>
        </header>


        </>
    )
}

export default Header