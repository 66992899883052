import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import tw from 'twin.macro'

import Container from './Container'
import A from './A'

import { flatListToHierarchical } from '../helpers'
import Logos from './Logos'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const FooterSocial = () => {
  const channels = [
    {
      label: 'Facebook',
      url: 'https://www.facebook.com/CooksPestControlInc',
      icon: 'facebook-f',
    },
    {
      label: 'Instagram',
      url: 'https://www.instagram.com/cookspestcontrol/',
      icon: 'instagram',
    },
    { label: 'Twitter', url: 'https://twitter.com/cookspest', icon: 'twitter' },
    {
      label: 'YouTube',
      url: 'https://www.youtube.com/channel/UCTkm5t5_6tXjzkv2YDrLvzg',
      icon: 'youtube',
    },
  ]

  return (
    <nav aria-label="Social Media" tw="mb-8">
      {channels.map((channel, i) => (
        <a
          aria-role="presentation"
          alt=""
          target="_blank"
          href={channel.url}
          css={[
            tw`inline-flex mr-3 items-center justify-center w-12 h-12 rounded bg-blue-600 text-white hover:(bg-blue-500) focus:(bg-blue-500) transition ease-in-out duration-150`,
            i === channels.length && tw`mr-0`,
          ]}
        >
          <span tw="sr-only">
            Follow Cook's Pest Control on {channel.label}
          </span>
          <FontAwesomeIcon icon={['fab', channel.icon]} size="2x" />
        </a>
      ))}
    </nav>
  )
}

const FooterNavigation = () => {
  const {
    wpMenu: { menuItems },
  } = useStaticQuery(graphql`
    {
      wpMenu(slug: { eq: "footer" }) {
        menuItems {
          nodes {
            key: id
            parentId
            label
            description
            path
          }
        }
      }
    }
  `)

  const navItems = flatListToHierarchical(menuItems.nodes)

  const NavItemList = ({ items }) => (
    <ul>
      {items.map(child => (
        <li>
          <A tw="inline-block mb-4" href={child.path || '#'}>
            {child.description ? (
              <FontAwesomeIcon icon={['fas', child.description]} tw="mr-2" />
            ) : null}
            {child.label}
          </A>
        </li>
      ))}
    </ul>
  )

  return (
    <nav
      aria-label="Footer"
      tw="px-4 mb-8 md:(grid grid-cols-2) lg:(grid-cols-8 col-gap-10 px-20) xl:(col-gap-16)"
    >
      {navItems.map(item => {
        let children = item.children
        const numCols = Math.round(item.children.length / 5)

        if (item.children.length > 5) {
          children = []

          for (let i = 0; i < numCols; i++) {
            children.push(item.children.slice(i * 5, i * 5 + 5))
          }
        }

        return (
          <div
            css={[
              tw`text-left mb-8 lg:(mb-0) col-span-2`,
              //   `grid-column: span ${numCols} / span ${numCols};`,
            ]}
          >
            <strong tw="block text-lg font-bold mb-4">{item.label}</strong>
            {Array.isArray(children[0]) ? (
              <div
                css={[
                  tw`grid col-gap-10 xl:(col-gap-16)`,
                  `grid-template-columns: repeat(${numCols}, minmax(0, 1fr))`,
                ]}
              >
                {children.map(column => (
                  <NavItemList items={column} />
                ))}
              </div>
            ) : (
              <NavItemList items={children} />
            )}
          </div>
        )
      })}
    </nav>
  )
}

const Footer = () => {
  return (
    <footer aria-label="Site Footer" tw="mt-20 mb-4">
      <Container tw="border-t border-gray-500 pt-12 text-center">
        <Logos.WithCookie tw="w-32 mx-auto mb-6" />
        <p tw="lg:(text-lg w-1/3) text-gray-800 mx-auto mb-12 px-4">
          We serve only the Southeast, so we’re experts at controlling pests
          that dwell in your area.
        </p>
        <FooterNavigation />
        <FooterSocial />
        <p tw="md:(text-lg) text-gray-800">
          © {new Date().getFullYear()} Cook’s Pest Control | All rights
          reserved.
        </p>
      </Container>
    </footer>
  )
}

export default Footer
