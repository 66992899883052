import React, { useEffect, useReducer, useState } from 'react'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import tw from 'twin.macro'
import Button from '../Button'
import { H2 } from '../Headings'

export const Label = tw.label`block font-bold text-gray-700 leading-4 mb-3`

const InputField = tw.input`text-base block w-full border border-gray-700 p-3 mb-8 focus:(border-blue-600)`

export const Input = props => <InputField type="text" {...props} />
export const Email = props => (
  <InputField type="email" {...props} autocomplete="email" />
)
export const Phone = props => (
  <InputField type="phone" {...props} autocomplete="tel" />
)
export const Number = props => <InputField type="number" {...props} />

export const Submit = props => (
  <Button type="submit" {...props}>
    {props.label}
    {props.loading ? (
      <FontAwesomeIcon
        icon={faSpinner}
        tw="ml-2 w-4 text-yellow-800 animate-spin"
      />
    ) : null}
  </Button>
)

export const Textarea = tw.textarea`text-base block w-full border border-gray-700 p-3 mb-8 focus:(border-blue-500) sm:(text-sm) min-h-50`

export const Select = tw.select`block w-full border border-gray-700 px-3 text-base mb-8 h-12 py-1 focus:(border-blue-500) sm:(text-sm)`

export const SelectField = ({ data, onChange, dispatch }) => {
  const choices = JSON.parse(data.choices)

  // auto select the first choice
  useEffect(() => {
    console.log()
    console.log(data, choices, { [`input_${data.id}`]: choices[0].text })
    dispatch({
      type: 'updateField',
      key: `input_${data.id}`,
      value: choices[0].text,
    })
  }, [])

  return (
    <Select onChange={onChange} tw="max-w-lg sm:(max-w-xs)">
      {choices.map(choice => (
        <option value={choice.id}>{choice.text} </option>
      ))}
    </Select>
  )
}

export const Checkbox = props => (
  <div {...props.css}>
    <input
      id={props.name}
      type="checkbox"
      css={[
        tw`inline-block focus:(border-blue-500) h-4 w-4 mr-2 text-blue-600 border-gray-300`,
      ]}
      value={props.label}
      {...props}
    />
    <Label for={props.name} css={[tw`inline-block font-normal`]}>
      {props.label}
    </Label>
  </div>
)

export const CheckboxField = ({ data, id, dispatch }) => {
  const choices = JSON.parse(data.choices)

  return (
    <div>
      {choices.map((choice, i) => (
        <Checkbox
          name={`${id}_${i}`}
          label={choice.text}
          value={choice.text}
          group={data.id}
          onChange={e => {
            if (!e.target.checked) {
              dispatch({ type: 'clearField', key: `input_${id}_${i}` })
            } else {
              dispatch({
                type: 'updateField',
                key: `input_${id}_${i}`,
                value: choice.text,
              })
            }
          }}
        />
      ))}
    </div>
  )
}

export const Radio = props => (
  <div {...props.css}>
    <input
      id={props.name}
      name={props.group}
      type="radio"
      css={[
        tw`inline-block focus:(border-blue-500) h-4 w-4 mr-2 text-blue-600 border-gray-300`,
      ]}
      value={props.value || props.name}
      checked={props.checked}
      onClick={e => {
        if (typeof props?.dispatch === 'function') {
          props.dispatch({
            type: 'updateField',
            key: `input_${props.id}`,
            value: e.target.value,
          })
        } else {
          console.log('props.dispatch', props?.dispatch)
        }
      }}
    />
    <Label for={props.name} css={[tw`inline-block`]}>
      {props.label}
    </Label>
  </div>
)

export const RadioField = ({ data, dispatch, id }) => {
  const choices = JSON.parse(data.choices)

  return (
    <div>
      {choices.map(choice => (
        <Radio
          name={choice.text}
          label={choice.text}
          value={choice.text}
          group={data.id}
          id={id}
          dispatch={dispatch}
        />
      ))}
    </div>
  )
}

export const Switch = ({ checked, alt, onClick }) => {
  return (
    <button
      onClick={onClick}
      type="button"
      aria-pressed={checked}
      css={[
        tw`bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-12 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:border-2 focus:border-blue-500`,
        checked && tw`bg-blue-600`,
      ]}
    >
      <span css={[tw`sr-only`]}>{alt}</span>
      <span
        aria-hidden="true"
        css={[
          tw`translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform border-none transition ease-in-out duration-200`,
          checked && tw`translate-x-5`,
        ]}
      ></span>
    </button>
  )
}

export const SectionField = ({ data }) => <H2>{data.label}</H2>

export default {
  Label,
  Input,
  Email,
  Phone,
  Number,
  Submit,
  Textarea,
  Select,
  SelectField,
  Checkbox,
  CheckboxField,
  Radio,
  RadioField,
  Switch,
  SectionField,
}
