import { Link } from 'gatsby'
import tw from 'twin.macro'

export const A = tw.a`text-blue-700 underline cursor-pointer
hover:(text-blue-400)
focus:(text-blue-400)
active:(text-blue-900)
`

export const ALink = A.withComponent(Link)

export default A