import tw, { styled } from 'twin.macro'

const headingClasses = tw`font-bold mb-2 text-gray-900 leading-tight`
const displayClasses = tw`uppercase font-condensed`

export const H1 = styled.h1(({display, center, right}) => [
    tw`text-5xl`,
    headingClasses,
    display && [tw`text-6xl`, displayClasses],
    center && tw`text-center`,
    right && tw`text-right`
])

export const H2 = styled.h2(({display, center, right}) => [
    tw`text-4xl`,
    headingClasses,
    display && [tw`text-5xl`, displayClasses],
    center && tw`text-center`,
    right && tw`text-right`
])

export const H3 = styled.h3(({display, center, right}) => [
    tw`text-3xl`,
    headingClasses,
    display && [tw`text-4xl`, displayClasses],
    center && tw`text-center`,
    right && tw`text-right`
])

export const H4 = styled.h4(({display, center, right}) => [
    tw`text-2xl`,
    headingClasses,
    display && [tw`text-3xl`, displayClasses],
    center && tw`text-center`,
    right && tw`text-right`
])

export const H5 = styled.h5(({display, center, right}) => [
    tw`text-xl`,
    headingClasses,
    display && [tw`text-2xl`, displayClasses],
    center && tw`text-center`,
    right && tw`text-right`
])