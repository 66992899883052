/**
 * Michael Panik, 12/8/20
 * 
 * Gonna make some notes in this file as it's a little messy with a lot of functionality.
 */
import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
import Cookies from 'js-cookie'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faMapMarkerAlt, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { A, ALink } from './A'
import { Input, Label } from './Form/FormControls'
import Button from './Button'
import { formatPhoneNumber, geoPromise} from '../helpers'
import { queryWordPress, getZipFromCoords } from '../api'

const TopArrow = () => (
    <div id="top-arrow" css={['bottom: 100%; left: 50%;', tw`w-16 absolute transform -translate-x-1/2`]}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241 103">
            <path d="M120.5 0L241 103H0z" fill="#fff"/>
            <path d="M120.5 36l54.5 67H66z" fill="#194798"/>
        </svg>
    </div>
)

/**
 * This is what actually goes in the location finder popover in the header.
 */
const LocationInfo = ({error, open, branch, zipCode, setZipCode, loading, setLoading}) => {
    // This manualZipCode is just simple state to hold whatever is typed in.
    const [manualZipCode, setManualZipCode] = useState(zipCode)

    // I should probably make the button in the form a proper submit input, but didn't
    // feel like digging into the Button component and extending an input[type="submit"]
    // for it. So one function called by the form submit and button click, sorry.
    const handleZipForm = e => {
        e.preventDefault()
        setZipCode(manualZipCode)
    }

    // Lots of stuff in here is confusting because of how I'm having to get data based on ZIP codes.
    // Any time you see branch.title, that's the name of the service area. When you see
    // branch.serviceAreaMeta.serviceAreaBranch[0].title, that's the name of the branch. Does that suck
    // and is it massively confusing? Yes. Am I sorry? Again, yes. I'm deeply sorry.
    return (
        <div
            css={[
                `left: 50%; width:30rem;`,
                tw`transition ease-in-out duration-150 transform lg:(-translate-x-1/2 absolute z-50 bg-white p-4 rounded-lg shadow-2xl mt-4)`,
                tw`hidden lg:(block pointer-events-none opacity-0 )`, // Closed state
                open && tw`block lg:(opacity-100 pointer-events-auto -translate-y-6)` // open state
            ]}
            aria-expanded={open}
        >
            <TopArrow />
            <div tw="text-center" id="location-pane"> 
                <div tw="text-3xl font-bold mb-4">
                    <FontAwesomeIcon icon={faMapMarkerAlt} tw="leading-4 text-yellow-600 mr-3" />
                    {/* add fallback text if the branch has not been set */}
                    {(branch != undefined)
                        ? branch.title 
                        : 'Find A Location'
                    }
                </div>
                <form tw="flex justify-center items-center border-b pb-6 mb-6" onSubmit={handleZipForm}>
                    <Label tw="mb-0 mr-2" htmlFor="zipCode" aria-label="zipCode">Zip Code:</Label>
                    <Input 
                        tw="mb-0 w-20 p-2 mr-2" 
                        type="text" 
                        id="zipCode" value={manualZipCode} 
                        maxLength="5"
                        onChange={e => {
                            // ensure that the text entered contains only digits.
                            if (!/\D/.test(e.target.value)){
                                setManualZipCode(e.target.value);
                            }
                        }} 
                    />
                    <Button tw="mr-4" onClick={handleZipForm}>Change</Button>
                    <ALink to="/branches/">View All</ALink>
                </form>
                <div tw="grid grid-cols-2">
                    <div tw="text-left">
                        
                        <>
                            <p tw="mb-4">
                                {/* Add a fallback link if the branch is undefined */}
                                {branch != undefined ? (
                                    <>
                                        <strong>Local Branch:</strong>
                                        <br />
                                        <ALink to={`/branches/${branch.serviceAreaMeta?.serviceAreaBranch[0]?.slug}/`}>
                                            {branch.serviceAreaMeta?.serviceAreaBranch[0]?.title}
                                        </ALink>
                                    </>
                                ) : (
                                    <>
                                        <strong>See All Branches:</strong>
                                        <br />
                                        <ALink to={`/branches`}>
                                            Branch Locator
                                        </ALink>
                                    </>
                                )}
                            </p>
                                {/* display store hours */}
                                {branch != undefined && (
                                    <p tw="mb-4">
                                        <strong>Office Hours:</strong>
                                        <br />
                                        {branch.serviceAreaMeta?.serviceAreaBranch[0]?.branchMeta?.branchOfficeHours}
                                    </p>
                                )}
                            <A href={`tel:${(branch != null) ? branch.serviceAreaMeta?.serviceAreaBranch[0]?.branchMeta?.branchOfficePhone: '8002399898'}`} tw="block text-xs xl:(text-sm)">
                                <FontAwesomeIcon icon={faMobileAlt} tw="mr-2" />
                                {/* add a fallback number if the branch is undefined */}
                                {(branch !== null) 
                                    ? formatPhoneNumber(branch.serviceAreaMeta?.serviceAreaBranch[0]?.branchMeta?.branchOfficePhone)
                                    : '(800) 239-9898'
                                }
                            </A>
                        </>
                        

                    </div>
                    <div>
                        <Button large to="/get-a-quote/">Get a Quote <FontAwesomeIcon icon={faChevronRight} tw="ml-8" /></Button>
                    </div>
                </div>
            </div>
        </div>
    )
}


// This is the section of the header that holds all location finder info.
export const LocationFinder = props => {
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true);
    const [latLng, setLatLng] = useState(null)
    const [zipCode, setZipCode] = useState(null)
    const [branch, setBranch] = useState(null)
    const [error, setError] = useState(null)

    const toggleOpen = () => setOpen(!open);

    const closeLocationPane = React.useCallback((e) => {
        if (!e.target.closest('#location-pane') && open){
            setOpen(false);
        }
    }, [open])
    

    /* event listener to close the location pane if the user clicks ouside it */
    useEffect(() => {
        document.addEventListener('click',  closeLocationPane)
        return () => document.removeEventListener('click',  closeLocationPane);
    }, [open])

    // useEffect(()=>{
    //     getCoordsFromIp()
    //         .then((result)=>{
    //             console.log(result);
    //         })
    //         .catch((error)=>{
    //             console.log({geoError: error});
    //         });
    // },[])

    // When the component is mounted, look for cached ZIP and branch data. If not
    // found, use navigator.geolocation to get your current position, and update
    // state
    useEffect(() => {
        const cachedBranch = Cookies.get('localResidentialBranchData') || null
        const cachedZip = Cookies.get('zipCode') || null
        
        if (cachedBranch && cachedZip) {
            setBranch(JSON.parse(cachedBranch))
            setZipCode(cachedZip)
            return
        } 
        setLoading(true);

        // get the users location in a promise
        geoPromise()
            .then((position) => {
                // set the latitude and longitude
                const {latitude, longitude} = position.coords;
                setLatLng([latitude, longitude]);
            })
            .catch((error) => {
                if (error.code == error.PERMISSION_DENIED) {

                    // user blocked location permissions
                    setError('Unable to determine your ZIP code automatically. Please enter it below.')

                    // automatically promopt the user for a location
                    // setOpen(true);
                } else {
                    setError(error.message)
                }
                setLoading(false);
            })
        
    }, [])

    // When latLng is set/updated, geocode with Google Maps API. Get back a bunch of stuff,
    // loop over it until you find a ZIP code, then set the Zip code state.
    useEffect(() => {
        if (!latLng) {
            return
        }
        setLoading(true)

        const [lat, lng] = latLng

        getZipFromCoords({lat, lng})
            .then(res => res.json())
            .then(json => {
                json.results[0].address_components.map(component => {
                    if(component.types.includes('postal_code')) {
                        setZipCode(component.long_name)
                    }
                })
            })
    }, [latLng])

    // When ZIP code state is updated, query WordPress to find service ares that
    // contain the ZIP code we pass. When one is found, set the branch state.
    useEffect(() => {
        if (!zipCode) {
            return
        }

        if (branch === null){
            setLoading(true)
        }

        queryWordPress(`{
            serviceAreas(where: {serviceAreaZip: "${zipCode}"}) {
                edges {
                    node {
                        title
                        slug
                        serviceAreaMeta {
                            serviceAreaBranch {
                                ... on Branch {
                                    title
                                    slug
                                    branchMeta {
                                        branchOfficeAddress
                                        branchOfficeCity
                                        branchOfficeState
                                        branchOfficeZip
                                        branchOfficeEmailAddress
                                        branchOfficePhone
                                        branchOfficeHours
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }`)
            .then(({data}) => {
                
                let branches = data?.serviceAreas?.edges
                
                if (branches.length) {
                    let branch = branches[0]?.node
                    setBranch(branch)
                    Cookies.set('localResidentialBranchData', branch)
                    Cookies.set('zipCode', zipCode)
                    setLoading(false)
                } else {
                    const errorMessage = 'Unable to locate a service area for your ZIP code.'
                    setError(errorMessage)
                    setLoading(false);
                }
                
            })
            .catch(error => {
                setLoading(false);
            })
            
    }, [zipCode])

    return (
        <div tw="relative">
            <div tw="flex items-center" {...props}>
                <a onClick={toggleOpen} tw="cursor-pointer">
                    <FontAwesomeIcon 
                        icon={faMapMarkerAlt} 
                        tw="leading-4 text-yellow-600 text-2xl mr-3 xl:(text-5xl mr-4) hidden lg:(block)" 
                    />
                </a>
                <div>

                    {/* branch title */}
                    {(loading) ? (
                        <div 
                            css={[
                                'height:15px;width:100px;margin-bottom:2px;',
                                 tw`bg-blue-200 rounded-full animate-pulse`
                            ]}
                        />
                    ) : (
                        <a onClick={toggleOpen} tw="cursor-pointer block font-bold text-blue-600 text-xl leading-none">
                            {/* add fallback text if a branch isn't defined */}
                            {
                                branch?.title 
                                || 'Find a Location'
                            }
                        </a>
                    )}

                    {/* change location */}
                    <div tw="text-gray-600 text-xs xl:(text-sm) underline leading-none mb-1">
                        <ALink 
                            to="/branches" 
                            onClick={(e)=>{
                                e.preventDefault();
                                toggleOpen()
                            }}
                        >
                            {(loading) ? (
                                <div 
                                    css={[
                                        'height:14px;width:120px;',
                                        tw`bg-blue-200 rounded-full animate-pulse`
                                    ]}
                                />
                            ) : (
                                <>
                                    Change Location
                                </>
                            )}
                        </ALink>
                    </div>

                    {/* phone number */}
                    {(loading) ? (
                        <div>
                            <FontAwesomeIcon icon={faMobileAlt} tw="mr-2 text-gray-200 animate-pulse" />
                            <div 
                                css={[
                                    'height:14px;width:80px;', 
                                    tw`inline-block bg-gray-300 rounded-full animate-pulse`
                                ]}
                            />
                        </div>
                    ) : (
                        <A href={`tel:${(branch != null) ? branch.serviceAreaMeta?.serviceAreaBranch[0]?.branchMeta?.branchOfficePhone : '800-239-9898' }`} tw="block text-xs xl:(text-sm)">
                            <FontAwesomeIcon icon={faMobileAlt} tw="mr-2" />
                            {/* Add a fallback number if a branch isn't set */}
                            {(branch !== null) 
                                ? formatPhoneNumber(branch.serviceAreaMeta?.serviceAreaBranch[0]?.branchMeta?.branchOfficePhone)
                                : '(800) 239-9898'
                            }
                        </A>
                    )}
                    <LocationInfo 
                        open={open} 
                        error={error} 
                        branch={branch} 
                        setZipCode={setZipCode}
                        loading={loading}
                        setLoading={setLoading} 
                        zipCode={zipCode} 
                    />
                </div>
            </div>
        </div>
    )
}