import React from "react"
import PropTypes from "prop-types"

import '../styles/global.css'
import HeaderProvider from '../context/Header';
import Header from "./Header"
import Footer from "./Footer"

import { library } from '@fortawesome/fontawesome-svg-core'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(far, fab, fas)

const Layout = ({ children, headerType }) => {
  return (
    <>
      <HeaderProvider headerType={headerType}>
        <Header />
      </HeaderProvider>
      <main id="main">
        {children}
      </main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerType: PropTypes.oneOf(['residential', 'commercial'])
}

Layout.defaultProps = {
  headerType: 'residential',
}

export default Layout
// uppercase