export const flatListToHierarchical = (
    data = [],
    { idKey = 'key', parentKey = 'parentId', childrenKey = 'children' } = {}
) => {
    const tree = []
    const childrenOf = {}
    data.forEach((item) => {
        const newItem = { ...item }
        const { [idKey]: id, [parentKey]: parentId = 0 } = newItem
        childrenOf[id] = childrenOf[id] || []
        newItem[childrenKey] = childrenOf[id]
        parentId
            ? (
                childrenOf[parentId] = childrenOf[parentId] || []
            ).push(newItem)
            : tree.push(newItem)
    })
    return tree
}

export const formatPhoneNumber = phone => {
    phone = phone.toString()

    if (phone.length === 11) {
        return `+${phone[0]} (${phone.substring(1, 4)}) ${phone.substring(4, 7)}-${phone.substring(7)}`
    } else if (phone.length === 10) {
        return `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`
    } else {
        return phone
    }
}

export const dateFormat = dateString => {
    const date = new Date(Date.parse(dateString))
    return `${date.getMonth() + 1}.${date.getDate()}.${date.getFullYear()}`
}

export const stripHTML = originalString => originalString.replace(/(<([^>]+)>)/gi, "")

export const getForm = (formData, id) => {
    if (formData && id) {
        // Filter returned data to get specific form
        const form = formData.edges.filter(function (form) {
            return parseInt(form.node.formId) === parseInt(id)
        })

        // If we have a form, clean up a little more before returning
        // Do so many crazy checks because each level needs checking
        // to stop errors
        if (
            typeof form[0] !== 'undefined' &&
            typeof form[0]['node'] !== 'undefined'
        ) {
            return form[0]['node']
        }
    }

    return false
}

export const inlineImagesOptions = { 
    wordPressUrl: process.env.GATSBY_WP_BASE_URL, 
    uploadsUrl: `${process.env.GATSBY_WP_BASE_URL}/app/uploads/` 
}

export const slugify = string => string.toLowerCase().replace(/\W+/g, '-');

export const geoPromise = () => (new Promise((resolve, reject) => {
    if (!"geolocation" in navigator) return reject({code: "Geolocation services not available"})

    let retryCount = 0;
    let retryLimit = 3;
    const iterateRetryCount = () => {
        retryCount++
        return retryCount;
    }

    const retriesExceeded = () =>  (retryCount >= retryLimit);

    let geoOptions = {
        maximumAge: 100000,
        timeout: 1000,
        enableHighAccuracy: true,
    }

    const disableHighAccurary = () => {
        geoOptions.enableHighAccuracy = false;
        return geoOptions;
    }

    const handleGeoSuccess = position => { 
        /*  returns a geoposition object
            https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPosition
            {
                coords: {
                    // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates
                    latitude: number | null,
                    longitude: number | null,
                    altitude: number | null,
                    accuracy: number | null,
                    altitudeAccuracy: number | null,
                    heading: number | null,
                    speed: number | null,
                }, 
                timestamp : DOMTimeStamp // https://developer.mozilla.org/en-US/docs/Web/API/DOMTimeStamp
            }
         */
        return resolve(position);
    };

    const handleGeoError = error => {
        /*  returns a geopositon error
            https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
            {
                code : 1 // PERMISSION_DENIED
                    | 2 // POSITION_UNAVAILABLE
                    | 3, // TIMEOUT
                message: string;
            }
         */
            const {PERMISSION_DENIED, TIMEOUT, POSITION_UNAVAILABLE} = error;
        switch(error.code) {
            case PERMISSION_DENIED:
                reject(error);
                break;
            case POSITION_UNAVAILABLE:
            case TIMEOUT:
            default:
                if (retryCount < retryLimit) {
                    disableHighAccurary();
                    iterateRetryCount();
                    getLocation();
                } else {
                    reject(error);
                }
                break;
        }
    }

    
    const getLocation = () => {
        navigator.geolocation
            .getCurrentPosition(handleGeoSuccess,handleGeoError, geoOptions)
    }

    getLocation();

}));