export async function queryWordPress(query) {
    const res = await fetch(process.env.GATSBY_WP_ENDPOINT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify({ query })
    })

    if (!res.ok) {
        const message = `An error has occured: ${res.status}`;
        throw new Error(message);
    }

    const json = await res.json()
    return json
}


export const submitForm = ({ id, formState }) => fetch(`${process.env.GATSBY_FORM_SERVICE_ENDPOINT}/forms/${id}`, {
    method: 'post',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    },
    body: JSON.stringify(formState)
}).then(res => res.json())

export const getZipFromCoords = ({ lat, lng }) => {
    return fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=postal_code&key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`)
}

// export const getCoordsFromIp = () => axios.post(
//     `https://www.googleapis.com/geolocation/v1/geolocate?key=${process.env.GATSBY_GOOGLE_MAPS_API_KEY}`,
//     { considerIp: true }, 
//     { 
//         timeout: 5000,
//         validateStatus : (status) => (status >= 200 && status < 500),
//     }
// );
